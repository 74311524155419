<template>
  <div style="width: 100%;height: 100vh;position: relative;overflow-y: auto">
    <div style="height: 264px;position: relative">
      <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
      <div class="albumdetail-top">
        <div style="margin-top: 15px;display: flex;flex-direction: row;align-items: flex-start;width: 100%;">
          <img v-if="albumInfo.cover_pic_url" :src="albumInfo.cover_pic_url" class="album-cover"
               style="border-radius: 5px">
          <img v-else src="../../../assets/default_collecton.png"
               class="album-cover">
          <div style="margin-left: 12px;">
            <p class="album-name">
              {{ albumInfo.album_name }}</p>
            <p class="album-intro">
              {{ albumInfo.album_intro }}</p>
            <div class="flex-row " style="flex-wrap: wrap;margin-top: 8px">
              <div class="counts-container">
                <p v-if="albumInfo.songs" style="display: inline-block">共{{ albumInfo.songs.length }}个内容</p>
                <p v-else>共0个内容</p>
              </div>
              <div class="counts-container">
                <p style="display: inline-block">
                  预估{{ Math.ceil(albumInfo.songs.length / 5) }}个课时</p>
              </div>
              <div class="counts-container">
                <p style="display: inline-block">
                  预计学习{{ Math.ceil(albumInfo.songs.length / 5 / 2) }}周</p>
              </div>
              <div class="common-tags-container" style="margin-top: 10px">
                <div v-for="tag in albumInfo.album_tag_arr" class="common-tags">
                  <p>{{ tag }}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="album-desc">
      <div class="tabLine">
        <div class="flex-row" style="align-items: center;position: relative;" @click="currentTab=1">
          <img src="../../../assets/share_collection_logo.png">
          <p style="margin-left: 10px;color: #333333;font-size: 16px;font-weight: bold;font-family: PingFang SC-Bold, PingFang SC; ">
            内容介绍</p>
          <div class="currentTab" v-if="currentTab===1"></div>
        </div>
        <div class="flex-row" style="align-items: center;margin-left: 20px;position: relative;" @click="currentTab=2">
          <img src="../../../assets/share_collection_logo.png">
          <p style="margin-left: 10px;color: #333333;font-size: 16px;font-weight: bold;font-family: PingFang SC-Bold, PingFang SC; ">
            音频列表</p>
          <div class="currentTab" v-if="currentTab===2"></div>
        </div>
      </div>
      <div v-if="currentTab===1">
        <div class="introPre">
          {{ albumInfo.simple_intro }}
        </div>
      </div>
      <div v-if="currentTab===2">
        <div style="margin-top: 24px">
          <div v-for="(song,index) in albumInfo.songs" class="song-item" style="position: relative;" :key="index"
               @click="gotoSong(song)">
            <p class="song-name"> {{ song.position + " " + song.song_name }}</p>
            <p class="song-intro">{{ song.song_name_sub }}</p>
            <div style="width: 100%;margin-top: 10px ;display: flex;flex-direction: row;align-items: center;">
              <img src="../../../assets/time_icon.png" style="width: 15px;height: 15px;object-fit: cover">
              <p :id="'audio_duration'+ index" class="audio-duration">
                {{ formatSeconds(song.song_audio_address_length) }}
              </p>
              <p class="song_name_abbrev" style="margin-left: 15px">让孩子学会{{ song.song_name_abbrev }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAlbumInfoV1} from '../../../api/keneng'
import '../../../assets/common/common.css'

export default {
  name: "AuditionAlbumDetail",
  data() {
    return {
      currentTab: 1,
      album_id: this.$route.query.id,
      albumInfo: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (false) {
        this.album_id = '5fce2bcea4640bb5318b45b4'
      }
      getAlbumInfoV1(this.album_id).then((res) => {
        let result = res.data
        this.albumInfo = result.data
        let temp = result.data.songs
        this.songs = temp.map((item, index) => {
          var position = index + 1
          if (position < 10) {
            position = "0" + position
          }
          item['position'] = position
          return item
        })
      })
    },
    gotoSong(song) {
      this.$router.push({
        path: '/audition-audio',
        query: {
          id: song._id.$id
        }
      })
    },
    formatSeconds(seconds) {
      let min = 0
      let sec = seconds
      if (seconds >= 60) {
        min = parseInt(seconds / 60)
        sec = parseInt(seconds - min * 60)
      }
      let str_min = min
      if (min < 10) {
        str_min = '0' + min
      }
      let str_sec = sec
      if (sec < 10) {
        str_sec = '0' + sec
      }
      return str_min + ':' + str_sec
    },
  }
}
</script>

<style scoped lang="less">

.album-name {
  color: white;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500
}

.albumdetail-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-top: -5px;
  position: relative;
  height: 228px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../../../assets/share_top_bg.png");
  background-size: 100% 100%;
}

.common-tags {
  margin-right: 20px;
  background: #DEE1FF;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20';
  font-weight: normal;
  color: #5D5BB5;
  padding: 4px 18px;
  box-sizing: border-box;
}

.counts-container {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 3px 10px;
  box-sizing: border-box;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #FFFFFF;
}

.introPre {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  margin-top: 24px;
  color: #454651;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFang SC-Regular, PingFang SC;
  line-height: 28px;
  text-indent: 2em
}

.album-title {
  font-size: 22px;
  font-family: 'Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20';
  font-weight: normal;
  color: #333333;
}

.album-intro {
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20';
  font-weight: normal;
  color: #9698A2;
  line-height: 22px;
}

::-webkit-scrollbar {
  display: none;
}

.album-cover {
  width: 96px;
  height: 96px;
  object-fit: cover;
}

.album-desc {
  padding: 20px 20px;
  box-sizing: border-box;
  top: 240px;
  position: absolute;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  min-height: 300px;
}

.tabLine {
  display: flex;
  align-items: center;
}

.currentTab {
  position: absolute;
  left: 30px;
  bottom: -12px;
  width: 58px;
  height: 2px;
  border-radius: 2px;
  background: rgb(108, 107, 191);
}

.audio-duration {
  margin-left: 6px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #666666;
}

.song_name_abbrev {
  padding: 3px 4px;
  box-sizing: border-box;
  background: #DEE1FF;
  border-radius: 4px;
  font-size: 8px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #5D5BB5;
  line-height: 14px;
}

.song-item {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 3px 15px 1px rgba(93, 91, 181, 0.16);
  border-radius: 6px;
  background: #FFFFFF;
  cursor: pointer;
}

.song-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #5D5BB5;
}

.song-intro {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 12px;
  font-size: 10px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #999999;
  line-height: 18px;
}
</style>

